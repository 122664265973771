<template>
    <section class="contact-us" id="contact">
        <div class="container">
            <div class="row">
                <!-- Información de contacto -->
                <div class="col-lg-6 mb-4 mb-lg-0">
                    <h2 class="contact-title">Contáctanos</h2>
                    <p class="contact-description">
                        Si tienes alguna pregunta o deseas saber más sobre nuestros servicios, no dudes en contactarnos.
                        Estamos aquí para ayudarte.
                    </p>
                    <ul class="contact-info">
                        <li><i class="bi bi-geo-alt-fill"></i> Calle 123, Bogotá, Colombia</li>
                        <li><i class="bi bi-envelope-fill"></i> contacto@solucionesunificadas.com</li>
                        <li><i class="bi bi-telephone-fill"></i> +57 322 963 4090</li>
                    </ul>
                </div>

                <!-- Formulario de contacto -->
                <div class="col-lg-6">
                    <div class="contact-form">
                        <h3 class="form-title">Envíanos un mensaje</h3>
                        <form @submit.prevent="submitForm">
                            <div class="mb-3">
                                <input type="text" class="form-control" v-model="formData.name" placeholder="Tu Nombre"
                                    required />
                            </div>
                            <div class="mb-3">
                                <input type="email" class="form-control" v-model="formData.email"
                                    placeholder="Tu Correo Electrónico" required />
                            </div>
                            <div class="mb-3">
                                <textarea class="form-control" v-model="formData.message" placeholder="Tu Mensaje"
                                    rows="4" required></textarea>
                            </div>
                            <button type="submit" class="btn btn-primary">Enviar Mensaje</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AppContact',
    data() {
        return {
            formData: {
                name: '',
                email: '',
                message: '',
            },
        };
    },
    methods: {
        submitForm() {
            console.log('Formulario enviado:', this.formData);
            alert('Tu mensaje ha sido enviado. Nos pondremos en contacto contigo pronto.');
            this.formData = {
                name: '',
                email: '',
                message: '',
            };
        },
    },
};
</script>

<style scoped>
.contact-us {
    padding: 80px 0;
    background-color: #f7f9fb;
}

.contact-title {
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 30px;
    color: #333;
}

.contact-description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 30px;
}

.contact-info {
    list-style: none;
    padding: 0;
    font-size: 1.1rem;
}

.contact-info li {
    margin-bottom: 15px;
    color: #333;
    display: flex;
    align-items: center;
}

.contact-info i {
    font-size: 1.5rem;
    margin-right: 15px;
    color: #0d6efd;
}

/* Estilos del formulario */
.contact-form {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.form-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 30px;
}

.form-control {
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 1rem;
}

.btn {
    border-radius: 30px;
    padding: 12px 30px;
    font-size: 1.1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.btn:hover {
    background-color: #0a58ca;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Responsividad */
@media (max-width: 992px) {
    .contact-title {
        font-size: 2.5rem;
    }

    .form-title {
        font-size: 1.5rem;
    }

    .contact-us {
        padding: 60px 0;
    }
}

@media (max-width: 768px) {
    .contact-title {
        font-size: 2.2rem;
    }

    .form-title {
        font-size: 1.3rem;
    }

    .contact-us {
        padding: 40px 0;
    }

    .btn {
        font-size: 1rem;
        padding: 10px 20px;
    }
}
</style>
