<template>
    <section class="hero" id="inicio">
        <video class="video-bg" autoplay loop muted playsinline>
            <source src="@/assets/videos/hero.mp4" type="video/mp4">
            Tu navegador no soporta el video tag.
        </video>
        <div class="overlay"></div>
        <div class="container text-center">
            <h1 class="display-4">Bienvenido a <span class="highlight">Soluciones Unificadas</span></h1>
            <p class="lead">Tu aliado estratégico en soluciones de TI que impulsan el crecimiento y la innovación.</p>
            <button class="btn btn-primary btn-lg custom-btn">Solicitar información</button>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AppHero'
}
</script>

<style scoped>
.hero {
    position: relative;
    overflow: hidden;
    min-height: 85vh;
    padding: 120px 15px; /* Espacio vertical en dispositivos grandes */
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    color: #fff;
}

.video-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120vw; /* Ajustar para que cubra el área */
    height: 120vh; /* Ajustar para que cubra el área */
    object-fit: cover;
    z-index: -1;
    opacity: 0.7;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
}

.container {
    position: relative;
    z-index: 2;
    max-width: 960px;
    margin: auto;
    padding: 0 15px; /* Mantener padding lateral */
    text-align: center;
}

.display-4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 3.5rem; /* Ajustar tamaño para que se vea más distribuido */
    font-weight: 900;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.8);
    margin-bottom: 20px; /* Espacio debajo del encabezado */
}

.lead {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    opacity: 0.9;
    max-width: 800px;
    margin: 0 auto 30px; /* Margen abajo para espacio adicional */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.highlight {
    color: #0D6EFD;
    font-weight: bold;
}

.btn {
    border-radius: 20px;
    background-color: #0D6EFD;
    color: #fff;
    padding: 12px 24px;
    font-size: 1.2rem;
    border: none;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #0a4ca3;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
    .hero {
        padding: 60px 15px; /* Menos padding en móviles */
        min-height: 100vh; /* Ajustar altura completa para móviles */
    }

    .display-4 {
        font-size: 2.5rem; /* Ajustar tamaño del texto */
    }

    .lead {
        font-size: 1.2rem; /* Ajustar tamaño del texto */
        margin-bottom: 20px; /* Espacio adicional en móviles */
    }

    .video-bg {
        width: 100vw; /* Ajustar el ancho del video */
        height: 100vh; /* Cubrir el alto del banner */
        object-fit: cover; /* Mantener proporciones del video */
    }
}
</style>
