<template>
    <section class="services" id="servicios">
        <div class="container text-center">
            <h2 class="services-title">Nuestros Servicios</h2>
            <div class="row justify-content-center">
                <!-- Categoría: Desarrollo de Software y Aplicaciones -->
                <div class="col-md-6 col-lg-4 d-flex">
                    <div class="category-card flex-grow-1">
                        <div class="icon-container">
                            <i class="bi bi-laptop service-icon"></i>
                        </div>
                        <h3>Desarrollo de Software y Aplicaciones</h3>
                        <p>Potenciamos tu negocio con soluciones tecnológicas estratégicas y personalizadas, diseñadas para optimizar procesos y maximizar el crecimiento.</p>
                        <ul class="service-list">
                            <li>Desarrollo de Software Personalizado</li>
                            <li>Diseño y Desarrollo de Páginas Web</li>
                            <li>Desarrollo de Aplicaciones Móviles</li>
                        </ul>
                        <button class="btn btn-primary mt-auto">Solicitar Información</button>
                    </div>
                </div>

                <!-- Categoría: Infraestructura de Red -->
                <div class="col-md-6 col-lg-4 d-flex">
                    <div class="category-card flex-grow-1">
                        <div class="icon-container">
                            <i class="bi bi-server service-icon"></i>
                        </div>
                        <h3>Infraestructura de Red</h3>
                        <p>Fortalecemos tu infraestructura con soluciones integrales de conectividad, garantizando un entorno tecnológico seguro, eficiente y escalable.</p>
                        <ul class="service-list">
                            <li>Cableado Estructurado</li>
                            <li>Instalación y Configuración de Racks</li>
                            <li>Instalación y Configuración de Cámaras de Seguridad CCTV</li>
                            <li>Implementación de Sistemas PBX</li>
                            <li>Soluciones Avanzadas de Contact Center</li>
                        </ul>
                        <button class="btn btn-primary mt-auto">Solicitar Información</button>
                    </div>
                </div>

                <!-- Categoría: Soluciones Metalmecánicas -->
                <div class="col-md-6 col-lg-4 d-flex">
                    <div class="category-card flex-grow-1">
                        <div class="icon-container">
                            <i class="bi bi-tools service-icon"></i>
                        </div>
                        <h3>Soluciones Metalmecánicas</h3>
                        <p>Forjamos soluciones metálicas de alta precisión, combinando tecnología avanzada con artesanía experta para proyectos duraderos y personalizados.</p>
                        <ul class="service-list">
                            <li>Soldadura MIG</li>
                            <li>Estructuras y Barandas</li>
                            <li>Gondolería y Estantería</li>
                            <li>Mobiliario y Casilleros</li>
                            <li>Trabajos Especiales</li>
                        </ul>
                        <button class="btn btn-primary mt-auto">Solicitar Información</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AppServices'
}
</script>

<style scoped>
/* Sección de Servicios */
.services {
    padding: 80px 0;
    background-color: #eef2f7;
    overflow: hidden;
}

.services-title {
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 50px;
    color: #333;
}

/* Tarjeta de categoría */
.category-card {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    text-align: left;
    margin-bottom: 40px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 450px; /* Altura mínima de las tarjetas */
}

.category-card:hover {
    transform: translateY(-15px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

/* Iconos de servicios */
.icon-container {
    width: 80px;
    height: 80px;
    background-color: #0d6efd;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.service-icon {
    font-size: 2.5rem;
    color: #fff;
}

/* Títulos de las tarjetas */
.category-card h3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

/* Descripción de las tarjetas */
.category-card p {
    color: #666;
    font-size: 1.1rem;
    margin-bottom: 20px;
    flex-grow: 1; /* Permite que el texto crezca si es necesario */
}

/* Lista de servicios */
.service-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
}

.service-list li {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.service-list li:before {
    content: '•';
    color: #0d6efd;
    font-weight: bold;
    display: inline-block;
    width: 20px;
    margin-right: 10px;
}

/* Botón de solicitud */
.btn {
    border-radius: 20px;
    background-color: #0d6efd;
    color: #fff;
    padding: 10px 20px;
    font-size: 1.1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.btn:hover {
    background-color: #0a58ca;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Animación de hover de iconos */
.icon-container::before {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    background-color: rgba(255, 255, 255, 0.1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.4s ease;
}

.icon-container:hover::before {
    opacity: 1;
    animation: pulse 1.2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0.9);
    }
}

/* Adaptabilidad a pantallas pequeñas */
@media (max-width: 768px) {
    .services-title {
        font-size: 2.2rem;
    }

    .category-card {
        padding: 30px;
        min-height: auto; /* Elimina la altura mínima en pantallas pequeñas */
    }

    .service-icon {
        font-size: 2rem;
    }

    .category-card h3 {
        font-size: 1.6rem;
    }
}
</style>
