<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top">
    <div class="container-fluid">
      <!-- Logo a la izquierda -->
      <a class="navbar-brand" href="#inicio">
        <img src="@/assets/logo.png" alt="Landrick" height="40">
      </a>

      <!-- Botón Toggler para pantallas pequeñas -->
      <div class="d-flex ms-auto d-lg-none">
        <button
          class="navbar-toggler me-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Usuario Dropdown Button en móviles -->
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-primary rounded-circle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-person"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item" href="#">Iniciar sesión</a></li>
            <li><a class="dropdown-item" href="#">Registrarse</a></li>
          </ul>
        </div>
      </div>

      <!-- Menú en desktop con anclas -->
      <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarNavDropdown"
      >
        <ul class="navbar-nav modern-menu me-3">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeSection === 'inicio' }"
              href="#inicio"
              @click="closeMenuOnMobile"
              >Inicio</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeSection === 'about' }"
              href="#about"
              @click="closeMenuOnMobile"
              >Nosotros</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeSection === 'servicios' }"
              href="#servicios"
              @click="closeMenuOnMobile"
              >Servicios</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeSection === 'contact' }"
              href="#contact"
              @click="closeMenuOnMobile"
              >Contacto</a
            >
          </li>
        </ul>

        <!-- Usuario Dropdown Button en desktop -->
        <div class="d-none d-lg-flex align-items-center">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary rounded-circle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-person"></i>
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
              <li><a class="dropdown-item" href="#">Iniciar sesión</a></li>
              <li><a class="dropdown-item" href="#">Registrarse</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <!-- Espacio a contenido -->
  <div class="space" style="padding-top: 70px;"></div>
</template>

<script>
export default {
  name: 'AppNavbar',
  data() {
    return {
      activeSection: 'inicio' // Sección activa
    };
  },
  mounted() {
    // Intersection Observer para detectar la sección activa
    const options = {
      root: null, // Observa toda la ventana
      threshold: [0.3, 0.6, 0.9], // Al menos un 30% visible para activar
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.activeSection = entry.target.id; // Cambia la sección activa
        }
      });
    }, options);

    // Observa las secciones
    const sections = document.querySelectorAll('section');
    sections.forEach((section) => observer.observe(section));

    // Añade smooth scroll a los enlaces
    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const targetId = link.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        window.scrollTo({
          top: targetElement.offsetTop - 70, // Ajusta la posición de scroll (compensación del navbar)
          behavior: 'smooth', // Scroll suave
        });
      });
    });
  },
  methods: {
    closeMenuOnMobile() {
      const navbarToggler = document.querySelector('.navbar-toggler');
      const navbarCollapse = document.querySelector('#navbarNavDropdown');

      if (navbarToggler && navbarCollapse.classList.contains('show')) {
        navbarToggler.click(); // Simula un clic en el botón del menú para cerrarlo
      }
    }
  }
};
</script>

<style scoped>
/* Estilo de la barra de navegación */
.navbar {
  background-color: rgba(255, 255, 255, 0.9); /* Fondo translúcido */
  backdrop-filter: blur(10px); /* Efecto de difuminado de fondo */
  padding: 0.75rem 1rem;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Líneas más modernas */
}

.navbar:hover {
  background-color: rgba(255, 255, 255, 1); /* Aumenta la opacidad al pasar el mouse */
}

/* Menú moderno */
.modern-menu .nav-link {
  font-size: 1.1rem;
  color: #333; /* Color de texto en estado normal */
  padding: 0.5rem 1rem;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 25px;
}

.modern-menu .nav-link:hover {
  color: #fff !important; /* Aseguramos que el texto sea blanco en estado hover */
  background-color: #0d6efd;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Efecto de sombra moderna */
}

.modern-menu .nav-link.active {
  color: #fff !important; /* Aseguramos que el color sea blanco en estado activo */
  background-color: #0d6efd; /* Fondo azul para el estado activo */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Efecto de sombra moderna */
}

/* Ajustes para móviles */
@media (max-width: 991px) {
  .navbar {
    padding: 1rem 0; /* Agregar más padding vertical en móviles */
  }

  /* Añadir margen superior fijo en el menú móvil */
  #navbarNavDropdown {
    margin-top: 20px; /* Espacio entre el menú y la parte superior */
  }
}
</style>
