<template>
    <section class="about-us" id="about">
        <div class="container">
            <div class="row align-items-center">
                <!-- Imagen a la izquierda en pantallas grandes, debajo del texto en móviles -->
                <div class="col-lg-6 order-lg-1 order-2 text-center text-lg-start">
                    <h2 class="about-title">Quiénes Somos</h2>
                    <p class="about-text">
                        En <span class="highlight">SOLUCIONES UNIFICADAS</span>, somos un equipo de expertos dedicados a
                        potenciar negocios mediante soluciones tecnológicas integradas y personalizadas.
                    </p>
                    <p class="about-text">
                        Nos especializamos en el desarrollo de software a la medida, diseño web, aplicaciones móviles, y
                        la implementación de infraestructuras tecnológicas robustas.
                    </p>
                    <p class="about-text">
                        Nuestra experiencia y compromiso con la innovación nos permiten ofrecer soluciones que no solo
                        resuelven los desafíos actuales sino que también preparan a tu empresa para el futuro.
                    </p>
                    <button class="btn btn-primary mt-4">Conócenos más</button>
                </div>

                <!-- Imagen, movida debajo del contenido en pantallas pequeñas -->
                <div class="col-lg-6 order-lg-2 order-1 mb-4 mb-lg-0">
                    <div class="about-image">
                        <img src="@/assets/about.jpg" alt="Quiénes Somos" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AppAbout'
}
</script>

<style scoped>
.about-us {
    padding: 100px 0;
    background: linear-gradient(135deg, #f7f9fb 0%, #eef2f7 100%);
}

/* Título del componente */
.about-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 30px;
    color: #333;
    position: relative;
    display: inline-block;
}

.about-title::after {
    content: '';
    width: 50px;
    height: 5px;
    background-color: #0d6efd;
    position: absolute;
    left: 0;
    bottom: -10px;
    border-radius: 3px;
}

/* Estilos del texto */
.about-text {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.8;
    text-align: justify;
}

.highlight {
    color: #0d6efd;
    font-weight: bold;
}

/* Estilos para la imagen */
.about-image {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-image img {
    width: 100%;
    height: auto;
    transform: scale(1);
    transition: transform 0.5s ease;
}

.about-image:hover img {
    transform: scale(1.05);
}

/* Estilos del botón */
.btn {
    padding: 12px 30px;
    border-radius: 30px;
    font-size: 1.1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.btn:hover {
    background-color: #0a58ca;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Responsividad */
@media (max-width: 992px) {
    .about-title {
        font-size: 2.5rem;
    }

    .about-text {
        font-size: 1.1rem;
    }

    .about-us {
        padding: 60px 0;
    }

    /* Orden para que la imagen esté debajo del texto en móviles */
    .about-image {
        margin-bottom: 30px;
    }
}

@media (max-width: 768px) {
    .about-title {
        font-size: 2.2rem;
    }

    .about-text {
        font-size: 1rem;
    }

    .about-us {
        padding: 40px 0;
    }

    .btn {
        font-size: 1rem;
        padding: 10px 20px;
    }
}
</style>
